import { useMemo } from "react";
import DataTable from "../../../components/DataTable";

export default function SampleResult({ data }) {
  const { columns, dataSrc } = useMemo(
    () => GenerateColumnsAndData(data),
    [data]
  );
  const options = {
    columns: columns,
    dataSource: dataSrc,
    //scroll: { x: 500, y: 200 },
    sx: { height: "100px" },
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
  };
  return <DataTable {...options}></DataTable>;
}

function GenerateColumnsAndData(data) {
  /*
  .OrderBy(s => s.TestItemTypeRank)
  .ThenBy(s => s.TestItemType)
  .ThenBy(s => s.TechnicianRank)
  .ThenBy(s => s.TestItemCode)
  */
  const dataObject = {};
  const columns = data.sort(sortFunction).map((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = {};
    }
    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    return {
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      //minWidth: 20,
      flex: 1,
      sortable: false,
      description:
        (x.TestItem != x.TestItemCode ? x.TestItemCode + " : " : "") +
        x.TestItem,
    };
  });

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }
  return { columns, dataSrc };
}

function sortFunction(a, b) {
  return (
    a.TestItemTypeRank - b.TestItemTypeRank ||
    a.TestItemType.localeCompare(b.TestItemType) ||
    a.TechnicianRank - b.TechnicianRank ||
    a.TestItemCode.localeCompare(b.TestItemCode)
  );
}
