import TreeView from "@mui/lab/TreeView";

export default function Tree(props) {
  return (
    <TreeView
      {...props}
      aria-label="customized"
    >
      {props.children}
    </TreeView>
  );
}
