import { Divider, Menu, MenuItem, Typography } from "@mui/material";

const TreeMenuSub = ({ item, anchorEl, openPop, setOpenPop, handleClick }) => {

    function handleItemClick(name,) {
        handleClick && handleClick(name)
    }

    return (
        <Menu anchorEl={anchorEl} open={openPop} onClose={() => setOpenPop(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right", }}>

            {item.items?.map((subItem, index) => {
                return (
                    <div key={index}>
                        <MenuItem
                            key={index}
                            sx={{ py: 0, minWidth: 180, }}
                            onClick={(e) => handleItemClick(subItem.name, e)}
                        >
                            <Typography key={index}>{subItem.label}</Typography>
                        </MenuItem>
                        {item.items?.length - 1 > index ? <Divider /> : <></>}
                    </div>
                );
            })}
        </Menu>
    );
}

export default TreeMenuSub;