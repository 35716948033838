const Colors = [
  { TestItemColour: "AliceBlue", hex: "#FFF0F8FF" },
  { TestItemColour: "AntiqueWhite", hex: "#FFFAEBD7" },
  { TestItemColour: "Aqua", hex: "#FF00FFFF" },
  { TestItemColour: "Aquamarine", hex: "#FF7FFFD4" },
  { TestItemColour: "Azure", hex: "#FFF0FFFF" },
  { TestItemColour: "Beige", hex: "#FFF5F5DC" },
  { TestItemColour: "Bisque", hex: "#FFFFE4C4" },
  { TestItemColour: "Black", hex: "#000000" },
  { TestItemColour: "BlanchedAlmond", hex: "#FFFFEBCD" },
  { TestItemColour: "Blue", hex: "#0000FF" },
  { TestItemColour: "BlueViolet", hex: "#FF8A2BE2" },
  { TestItemColour: "Brown", hex: "#FFA52A2A" },
  { TestItemColour: "BurlyWood", hex: "#FFDEB887" },
  { TestItemColour: "CadetBlue", hex: "#FF5F9EA0" },
  { TestItemColour: "Chartreuse", hex: "#FF7FFF00" },
  { TestItemColour: "Chocolate", hex: "#FFD2691E" },
  { TestItemColour: "Coral", hex: "#FFFF7F50" },
  { TestItemColour: "CornflowerBlue", hex: "#FF6495ED" },
  { TestItemColour: "Cornsilk", hex: "#FFFFF8DC" },
  { TestItemColour: "Crimson", hex: "#FFDC143C" },
  { TestItemColour: "Cyan", hex: "#FF00FFFF" },
  { TestItemColour: "DarkBlue", hex: "#FF00008B" },
  { TestItemColour: "DarkCyan", hex: "#FF008B8B" },
  { TestItemColour: "DarkGoldenrod", hex: "#FFB8860B" },
  { TestItemColour: "DarkGray", hex: "#FFA9A9A9" },
  { TestItemColour: "DarkGreen", hex: "#FF006400" },
  { TestItemColour: "DarkKhaki", hex: "#FFBDB76B" },
  { TestItemColour: "DarkMagenta", hex: "#FF8B008B" },
  { TestItemColour: "DarkOliveGreen", hex: "#FF556B2F" },
  { TestItemColour: "DarkOrange", hex: "#FFFF8C00" },
  { TestItemColour: "DarkOrchid", hex: "#FF9932CC" },
  { TestItemColour: "DarkRed", hex: "#FF8B0000" },
  { TestItemColour: "DarkSalmon", hex: "#FFE9967A" },
  { TestItemColour: "DarkSeaGreen", hex: "#FF8FBC8F" },
  { TestItemColour: "DarkSlateBlue", hex: "#FF483D8B" },
  { TestItemColour: "DarkSlateGray", hex: "#FF2F4F4F" },
  { TestItemColour: "DarkTurquoise", hex: "#FF00CED1" },
  { TestItemColour: "DarkViolet", hex: "#FF9400D3" },
  { TestItemColour: "DeepPink", hex: "#FFFF1493" },
  { TestItemColour: "DeepSkyBlue", hex: "#FF00BFFF" },
  { TestItemColour: "DimGray", hex: "#FF696969" },
  { TestItemColour: "DodgerBlue", hex: "#FF1E90FF" },
  { TestItemColour: "Firebrick", hex: "#FFB22222" },
  { TestItemColour: "FloralWhite", hex: "#FFFFFAF0" },
  { TestItemColour: "ForestGreen", hex: "#FF228B22" },
  { TestItemColour: "Fuchsia", hex: "#FFFF00FF" },
  { TestItemColour: "Gainsboro", hex: "#FFDCDCDC" },
  { TestItemColour: "GhostWhite", hex: "#FFF8F8FF" },
  { TestItemColour: "Gold", hex: "#FFFFD700" },
  { TestItemColour: "GoldenRod", hex: "#FFDAA520" },
  { TestItemColour: "Gray", hex: "#FF808080" },
  { TestItemColour: "Green", hex: "#FF008000" },
  { TestItemColour: "GreenYellow", hex: "#FFADFF2F" },
  { TestItemColour: "Honeydew", hex: "#FFF0FFF0" },
  { TestItemColour: "HotPink", hex: "#FFFF69B4" },
  { TestItemColour: "IndianRed", hex: "#FFCD5C5C" },
  { TestItemColour: "Indigo", hex: "#FF4B0082" },
  { TestItemColour: "Ivory", hex: "#FFFFFFF0" },
  { TestItemColour: "Khaki", hex: "#FFF0E68C" },
  { TestItemColour: "Lavender", hex: "#FFE6E6FA" },
  { TestItemColour: "LavenderBlush", hex: "#FFFFF0F5" },
  { TestItemColour: "LawnGreen", hex: "#FF7CFC00" },
  { TestItemColour: "LemonChiffon", hex: "#FFFFFACD" },
  { TestItemColour: "LightBlue", hex: "#FFADD8E6" },
  { TestItemColour: "LightCoral", hex: "#FFF08080" },
  { TestItemColour: "LightCyan", hex: "#FFE0FFFF" },
  { TestItemColour: "LightGoldenrodYellow", hex: "#FFFAFAD2" },
  { TestItemColour: "LightGray", hex: "#FFD3D3D3" },
  { TestItemColour: "LightGreen", hex: "#FF90EE90" },
  { TestItemColour: "LightPink", hex: "#FFFFB6C1" },
  { TestItemColour: "LightSalmon", hex: "#FFFFA07A" },
  { TestItemColour: "LightSeaGreen", hex: "#FF20B2AA" },
  { TestItemColour: "LightSkyBlue", hex: "#FF87CEFA" },
  { TestItemColour: "LightSlateGray", hex: "#FF778899" },
  { TestItemColour: "LightSteelBlue", hex: "#FFB0C4DE" },
  { TestItemColour: "LightYellow", hex: "#FFFFFFE0" },
  { TestItemColour: "Lime", hex: "#FF00FF00" },
  { TestItemColour: "LimeGreen", hex: "#FF32CD32" },
  { TestItemColour: "Linen", hex: "#FFFAF0E6" },
  { TestItemColour: "Magenta", hex: "#FFFF00FF" },
  { TestItemColour: "Maroon", hex: "#FF800000" },
  { TestItemColour: "MediumAquamarine", hex: "#FF66CDAA" },
  { TestItemColour: "MediumBlue", hex: "#FF0000CD" },
  { TestItemColour: "MediumOrchid", hex: "#FFBA55D3" },
  { TestItemColour: "MediumPurple", hex: "#FF9370DB" },
  { TestItemColour: "MediumSeaGreen", hex: "#FF3CB371" },
  { TestItemColour: "MediumSlateBlue", hex: "#FF7B68EE" },
  { TestItemColour: "MediumSpringGreen", hex: "#FF00FA9A" },
  { TestItemColour: "MediumTurquoise", hex: "#FF48D1CC" },
  { TestItemColour: "MediumVioletRed", hex: "#FFC71585" },
  { TestItemColour: "MidnightBlue", hex: "#FF191970" },
  { TestItemColour: "MintCream", hex: "#FFF5FFFA" },
  { TestItemColour: "MistyRose", hex: "#FFFFE4E1" },
  { TestItemColour: "Moccasin", hex: "#FFFFE4B5" },
  { TestItemColour: "NavajoWhite", hex: "#FFFFDEAD" },
  { TestItemColour: "Navy", hex: "#FF000080" },
  { TestItemColour: "OldLace", hex: "#FFFDF5E6" },
  { TestItemColour: "Olive", hex: "#FF808000" },
  { TestItemColour: "OliveDrab", hex: "#FF6B8E23" },
  { TestItemColour: "Orange", hex: "#FFFFA500" },
  { TestItemColour: "OrangeRed", hex: "#FFFF4500" },
  { TestItemColour: "Orchid", hex: "#FFDA70D6" },
  { TestItemColour: "PaleGoldenrod", hex: "#FFEEE8AA" },
  { TestItemColour: "PaleGreen", hex: "#FF98FB98" },
  { TestItemColour: "PaleTurquoise", hex: "#FFAFEEEE" },
  { TestItemColour: "PaleVioletRed", hex: "#FFDB7093" },
  { TestItemColour: "PapayaWhip", hex: "#FFFFEFD5" },
  { TestItemColour: "PeachPuff", hex: "#FFFFDAB9" },
  { TestItemColour: "Peru", hex: "#FFCD853F" },
  { TestItemColour: "Pink", hex: "#FFFFC0CB" },
  { TestItemColour: "Plum", hex: "#FFDDA0DD" },
  { TestItemColour: "PowderBlue", hex: "#FFB0E0E6" },
  { TestItemColour: "Purple", hex: "#FF800080" },
  { TestItemColour: "Red", hex: "#FFFF0000" },
  { TestItemColour: "RosyBrown", hex: "#FFBC8F8F" },
  { TestItemColour: "RoyalBlue", hex: "#FF4169E1" },
  { TestItemColour: "SaddleBrown", hex: "#FF8B4513" },
  { TestItemColour: "Salmon", hex: "#FFFA8072" },
  { TestItemColour: "SandyBrown", hex: "#FFF4A460" },
  { TestItemColour: "SeaGreen", hex: "#FF2E8B57" },
  { TestItemColour: "SeaShell", hex: "#FFFFF5EE" },
  { TestItemColour: "Sienna", hex: "#FFA0522D" },
  { TestItemColour: "Silver", hex: "#FFC0C0C0" },
  { TestItemColour: "SkyBlue", hex: "#FF87CEEB" },
  { TestItemColour: "SlateBlue", hex: "#FF6A5ACD" },
  { TestItemColour: "SlateGray", hex: "#FF708090" },
  { TestItemColour: "Snow", hex: "#FFFFFAFA" },
  { TestItemColour: "SpringGreen", hex: "#FF00FF7F" },
  { TestItemColour: "SteelBlue", hex: "#FF4682B4" },
  { TestItemColour: "Tan", hex: "#FFD2B48C" },
  { TestItemColour: "Teal", hex: "#FF008080" },
  { TestItemColour: "Thistle", hex: "#FFD8BFD8" },
  { TestItemColour: "Tomato", hex: "#FFFF6347" },
  { TestItemColour: "Transparent", hex: "#00FFFFFF" },
  { TestItemColour: "Turquoise", hex: "#FF40E0D0" },
  { TestItemColour: "Violet", hex: "#FFEE82EE" },
  { TestItemColour: "Wheat", hex: "#FFF5DEB3" },
  { TestItemColour: "White", hex: "#FFFFFFFF" },
  { TestItemColour: "WhiteSmoke", hex: "#FFF5F5F5" },
  { TestItemColour: "Yellow", hex: "#FFFFFF00" },
  { TestItemColour: "YellowGreen", hex: "#FF9ACD32" },
  { TestItemColour: "#009A4F", hex: "#009A4F" },
  { TestItemColour: "#140A9A", hex: "#140A9A" },
];

export default Colors;
