export const Steps = [
  { Name: "All Steps", Id: 0 },
  { Name: "Register", Id: 1 },
  { Name: "Laboratory", Id: 2 },
  { Name: "Quality-Check", Id: 3 },
  { Name: "Auto-Evaluate", Id: 4 },
  { Name: "Evaluate", Id: 5 },
  { Name: "Approval", Id: 6 },
];
export default Steps;

// export const Step = {
//   AllSteps: 0,
//   Register: 1,
//   Laboratory: 2,
//   QualityCheck: 3,
//   AutoEvaluate: 4,
//   Evaluate: 5,
//   Approval: 6,
// };
// export { Step };
