import DataTable from "../../../components/DataTable";
import { toLocalDayDate3 } from "../../../utils";

export default function WebsiteNewsTable(props) {
    const { data, rowsSelected, } = props;

    const options = {
        columns: GetColumns(),
        dataSource: data,
        rowsSelected: rowsSelected,
        rowIdProperty: "WebsiteNewsId",
        isDisableColumnFilter: true,
        minHeight: 75,
        heightValue: 100,
    };

    return <DataTable {...options} />;
}

const GetColumns = () => [
    {
        headerName: "Heading",
        field: "HeadingHtml",
        flex: 1
    },
    {
        headerName: "Order",
        field: "DisplayRank",
        width: 50,
    },
    {
        headerName: "From",
        field: "DisplayFrom",
        width: 110,
        valueFormatter: ({ value }) => toLocalDayDate3(value),
    },
    {
        headerName: "To",
        field: "DisplayTo",
        width: 110,
        valueFormatter: ({ value }) => toLocalDayDate3(value),
    },
]