import DataTable from "../../../components/DataTable";
import { AutoEvaluationOverviewColumns } from "./AutoEvaluationDashboardColumns";

export default function AutoEvaluationOverviewTable({ data }) {
  const options = {
    columns: AutoEvaluationOverviewColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.LaboratoryId + "" + (x.AutoEvalLatest || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: {
      height: 240,
    },
  };
  return <DataTable {...options}></DataTable>;
}
