import DataTable from "../../../../components/DataTable";

export default function EmailTable({
  data,
  rowsSelectedEmail,
  emailReportDataCheckbox,
}) {
  const fromFluidOptions = {
    columns: emailTableColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.Username,
    })),
    rowIdProperty: "keyProp",
    checkBoxSelect: true,
    selectedRowIds: emailReportDataCheckbox,
    isDisableColumnFilter: false,
    rowsSelected: rowsSelectedEmail,
    sx: { height: 660 },
    checkboxColor: "#000000",
    selectedRowColor: "transparent",
    selectedTextColor: "#000000",
  };

  return <DataTable {...fromFluidOptions} />;
}

const emailTableColumns = [
  {
    headerName: "Username/Email",
    field: "Username",
    minWidth: 200,
  },
  {
    headerName: "Name",
    field: "DisplayName",
    minWidth: 170,
    flex: 1,
  },
  {
    headerName: "Komatsu",
    field: "IsKomatsu",
    valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    minWidth: 170,
    flex: 1,
  },
];
