export default function getNodeId(pathItems) {
  return pathItems.map((x) => x.name + "_" + x.val).join("/");
}

export const parseNodeId = (nodeId) => {
  const items = nodeId?.split("/");
  const obj = {};
  let nodeType;
  const lastIndex = items?.length - 1;
  items?.forEach((item, i) => {
    const [name, val] = item.split("_");
    obj[name] = val;

    if (lastIndex === i) nodeType = name;
  });

  return { ...obj, nodeType };
};
