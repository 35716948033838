import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const TestItemSchema = yup.object().shape({
  TestItem: yup
    .string()
    .transform((value) => value || "")
    .required("Long Name is required."),
  TestItemCode: yup
    .string()
    .transform((value) => value || "")
    .required("Short Name is required."),
  XmlTag: yup
    .string()
    .transform((value) => value || "")
    .required("XML Name is required."),
  LimitOption: yup
    .string()
    .transform((value) => value || "")
    .required("Limit Options is required."),
  TestItemTypeId: yup
    .number()
    .transform((value) => value || 0)
    .required("Type is required.")
    .positive("Type is required.")
    .integer(),
});

export function ValidateTestItem(input) {
  return new Promise(async function (resolve, reject) {
    try {
      TestItemSchema.validate(input, { abortEarly: false })
        .then(function () {
          const {
            TestItemCode: Code,
            ShowCodeOnReports: ShowCode,
            DisplayOption,
            ShowOnReports,
            DisplayUnits: Units,
            DisplayStandard: Standard,
            DisplayDescription: Description,
            LimitOption: Limitoption,
            XmlTag: XmlTagName,
            TestItemColour: Colour,
            Representations = [],
            //DeletedRepresentations,
            ...rest
          } = input;
          const IsShowIfValue = ShowOnReports === 2;
          const HideFromCustomer = ShowOnReports === 1;
          const data = {
            TestItem: {
              ...rest,
              Screen: "Test Items",
              Code,
              ShowCode,
              DisplayOption,
              ShowOnReports,
              Standard,
              Description,
              Limitoption,
              XmlTagName,
              Colour: Colour || null,
              HideFromCustomer,
              IsShowIfValue,
            },
            Representations: Representations.filter(
              (x) =>
                !x.deleted &&
                (x.DisplayValue || x.DisplayValue === 0) &&
                (x.UnderlyingValue || x.UnderlyingValue === 0)
            ).map((x) =>
              x.isNew ? { ...x, TestItemRepresentationId: null } : x
            ),
            DeletedRepresentations: Representations.filter(
              (x) => !x.isNew && x.deleted
            ).map((x) => x.TestItemRepresentationId),
          };
          resolve({ data });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}
