import moment from "moment/moment";
import GetStepNames from "../../../common/GetStepNames";
import { TrayDateFormat, UserDateFormat } from "../../../utils";
export const MissedSamplesColumns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    minWidth: 120,
  },
  {
    headerName: "Tray",
    field: "Tray",
    minWidth: 80,
  },
  {
    headerName: "Tray Date",
    field: "TrayDate",
    valueFormatter: (params) =>
      moment(params.value, TrayDateFormat).format(UserDateFormat),
    minWidth: 150,
  },
  {
    headerName: "SampleNumber",
    field: "SampleNumber",
    minWidth: 150,
  },
  {
    headerName: "Step",
    field: "Step",
    valueFormatter: ({ value }) => GetStepNames(value).mid,
    minWidth: 150,
  },
  {
    headerName: "Hold Type",
    field: "HoldType",
    minWidth: 150,
  },
  {
    headerName: "Added By",
    field: "Added By",
    minWidth: 150,
  },
  {
    headerName: "Hold Until",
    field: "HoldUntil",
    minWidth: 150,
  },
  {
    headerName: "Hold Reason",
    field: "",
    minWidth: 300,
  },
];
