import DataTable from "../../../components/DataTable";
import { AutoEvaluationDetailsColumns } from "./AutoEvaluationDashboardColumns";

export default function AutoEvaluationDetailTable({ data }) {
  const options = {
    columns: AutoEvaluationDetailsColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.LaboratoryId + "" + (x.Agree || ""),
    })),
    rowIdProperty: "keyProp",
    cellStylingHandler: "autoEvaluation",
    isDisableColumnFilter: true,
    sx: {
      height: 390,
    },
  };
  return <DataTable {...options}></DataTable>;
}
