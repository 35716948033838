import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const RegistrationSchema = yup.object().shape({
  FluidId: yup
    .number()
    .transform((value) => value || 0)
    .when("FluidFormatted", {
      is: (val) => !!val,
      then: yup
        .number()
        .required("Selected Fluid is not available.")
        .positive("Selected Fluid is not available.")
        .integer(),
      otherwise: yup
        .number()
        .required("Fluid is required.")
        .positive("Fluid is required.")
        .integer(),
    }),
  TestSetId: yup
    .number()
    .transform((value) => value || 0)
    .required("Test set is required.")
    .positive("Test set is required.")
    .integer(),
  Sampled: yup
    .string()
    .transform((value) => value || "")
    .required("Date sampled is required."),
  ComponentId: yup
    .number()
    .transform((value) => value || 0)
    .required("Component is required.")
    .positive("Component is required.")
    .integer(),
});

export function ValidateRegistration(input) {
  return new Promise(async function (resolve, reject) {
    try {
      RegistrationSchema.validate(input, { abortEarly: false })
        .then(function () {
          const {
            SampleNumber,
            SampleNote,
            ComponentId,
            ComponentNote,
            UnitId,
            UnitNote,
            SiteId,
            SiteNote,
            CustomerId,
            CustomerNote,
            CustomerComments,
          } = input;
          const data = {
            Registration: {
              ...input,
              CompChangedHours:
                input.CompChangedHours === "" ? null : input.CompChangedHours,
              CompRepairedHours:
                input.CompRepairedHours === "" ? null : input.CompRepairedHours,
              FluidChangedAt:
                input.FluidChangedAt === "" ? null : input.FluidChangedAt,
              MeterChangedHours:
                input.MeterChangedHours === "" ? null : input.MeterChangedHours,
              Topup: input.Topup === "" ? null : input.Topup,
              MeterReading:
                input.MeterReading === "" ? null : input.MeterReading,
              ComponentReading:
                input.ComponentReading === "" ? null : input.ComponentReading,
              FluidHours: input.FluidHours === "" ? null : input.FluidHours,
            },
            Notes: {
              SampleNumber,
              SampleNote,
              ComponentId,
              ComponentNote,
              UnitId,
              UnitNote,
              SiteId,
              SiteNote,
              CustomerId,
              CustomerNote,
              CustomerComments,
            },
          };
          resolve({ data });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}

/*
 SampleNumber: 20230120100102,
  Sif: "00236858",
  ComponentId: 634680,
  OwnerLaboratoryId: 1,
  IsInternalOnly: false,
  MeterReading: 13945,
  ComponentReading: 13945,
  MeterChangedAt: null,
  MeterChangedAtDate: "2022-10-23T12:00:00",
  MeterChanged: false,
  MeterChangedEventId: null,
  CompChangedHours: null,
  ComponentChangedAtDate: "2022-10-23T12:00:00",
  ComponentChanged: false,
  CompRepairedHours: null,
  ComponentRepairedAtDate: "2022-10-23T12:00:00",
  ComponentRepaired: false,
  FluidChangedAt: null,
  FluidChangedAtDate: "2022-10-23T12:00:00",
  FluidChanged: false,
  FluidChangedEventId: null,
  FluidHours: 568,
  FluidFormatted: "MOBIL TRANS HD 30W",
  Topup: null,
  FilterChanged: true,
  MakeFluidDefault: false,
  ApplyFluidToAllSamples: false,
  MakeTestSetDefault: false,
  Sampled: "2022-10-23T12:00:00",
  SampledAssumed: true,
  JobNumber: null,
  SampleOptionId: 0,
  ServiceTypeId: 2,
  SampleTypeId: 1,
  UserId: user.DefaultLaboratoryId,,
  Screen: "Registration",
  Component: "Hydraulic System",
  Unit: "Unit174841",
  Site: "Site43841",
  Customer: "19010",
  UnitId: 174841,
  SiteId: 43840,
  CustomerId: 18985,
  SampleNote: null,
  ComponentNote: null,
  UnitNote: "test",
  SiteNote: "test",
  CustomerNote:
    "CAT recommends that oil-fuel dilution checks not be carried out until the dilution figures get to 6%.",
  CustomerComments: "",
  Step: 1,
  RegistrationId: 236858,
  regSampleNo: 20230120100101,
  LastFluidHours: 2244,
  LastMeterReading: 13377,
  LastComponentHours: 13377,
*/
