import AppDialog from "./AppDialog";
import { useScreenPopups } from "./useScreenPopups";

export default function GroupDialogs({ open, component, width, title, close, dialogType, screenDialogsProps, ...props }) {

  const dialogOptions = useScreenPopups({
    dialogType: dialogType.type,
    data: dialogType.data || {},
    ...screenDialogsProps
  });

  return (
    <AppDialog
      open={dialogType?.open}
      title={dialogOptions?.title}
      maxWidth={dialogOptions?.width || "lg"}
      handleClose={close}
    >
      {dialogOptions?.component}
    </AppDialog>
  );
}
