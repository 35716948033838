const productColors = (product) => {
    if (product === "KOWA" || product === 2) {
        return { backgroundColor: "Navy", color: "#fff" };
    }
    else if (product === "Labcheck" || product === 4) {
        return { backgroundColor: "ForestGreen", color: "#fff" };
    }
    else if (product === "KOWA Archive" || product === 21) {
        return { backgroundColor: "SteelBlue", color: "#fff" };
    }
    else if (product === "Labcheck Archive" || product === 22) {
        return { backgroundColor: "MediumSeaGreen", color: "#fff" };
    }
    else if (product === "Bureau Veritas" || product === 3) {
        return { backgroundColor: "Crimson", color: "#fff" };
    }
    else if (product === "R&T Lab" || product === 8) {
        return { backgroundColor: "Fuchsia", color: "#fff" };
    }
    else if (product === "Techenomics" || product === 25) {
        return { backgroundColor: "Purple", color: "#fff" };
    }
    else if (product === "Import" || product === 18) {
        return { backgroundColor: "Black", color: "#fff" };
    }
    else if (product === "Product Holder 1" || product === "Product Holder 2" || product === "Product Holder 3") {
        return { backgroundColor: "GoldenRod", color: "#fff" };
    }
    return { backgroundColor: "", color: "#000" };
}
export default productColors;
