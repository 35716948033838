import { Button, Card, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DataTable from "../../../components/DataTable";
import Form, { FormActions } from "../../../components/Form";
import useFetch from "../../../hooks/useFetch";
import { toBasicLocalDate, } from "../../../utils";
import { MASTER_DATA_APIS } from "../../../common/apis";
import { SaveIcon, } from "../../../icons/ButtonIcons";
import { FileUpload } from "@mui/icons-material";
import { useRoot } from "../../../rootContext";

export default function SpecialReportTemplates() {

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const { post, postFile } = useFetch();
  const { CanAdministrate } = useRoot();

  const [auditsData, setAuditsData] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [infoMessage, setInfoMessage] = useState();
  // const [contactId, setContactId] = useState();
  // const [propId, setPropId] = useState();

  // const [editContactDialogueOpen, setEditContactDialogueOpen] = useState(false);
  //Call api for user dropdown data
  // useEffect(() => {
  //   document.title = "Audit Log";
  //   (async function () {
  //     let resData = await post(MASTER_DATA_APIS.AUDIT_LOG_USERS, {
  //       IsAdministrator: true,
  //     });
  //     if (resData) {
  //       resData.unshift({ Username: 0, FullName: "All Users" });
  //     }
  //     setUsersDropdown(resData);
  //   })();
  // }, []);
  //call api for search audit data
  async function view() {
    let responseData = await post(MASTER_DATA_APIS.AUDIT_LOGS, {
      ...data,
      Username: data.Username ? data.Username : null,
      IsAdministrator: true,
    });
    let userName =
      usersDropdown?.find((item) => item.Username === data?.Username)
        ?.FullName ?? "";
    if (responseData.length > 0) {
      setAuditsData(responseData);

      setInfoMessage(`${responseData.length} logs found for ${userName}`);
    } else {
      setAuditsData();
      setInfoMessage(`No logs were found for ${userName}`);
    }
  }
  const tblOptions = {
    columns: GetColumns(),
    dataSource: (auditsData || []).map((x) => ({
      ...x,
      keyProp:
        x.Username +
        "" +
        (x.FullName || "") +
        "" +
        (x.Added || "") +
        "" +
        (x.Event || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 700 },
  };
  //Export excel
  function exportToExcelClick() {
    let fileName =
      "Audit Log " +
      toBasicLocalDate(data?.DateFrom) +
      "-" +
      toBasicLocalDate(data?.DateTo) +
      " Run";
    postFile(
      MASTER_DATA_APIS.EXPORT_AUDIT_LOGS,
      {
        ...data,
        Username: data.Username ? data.Username : null,
        IsAdministrator: true,
      },
      fileName
    );
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                usersDropdown,
                view,
                exportToExcelClick,
                auditsData,
                infoMessage,
                CanAdministrate)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {auditsData && auditsData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns() {
  return [
    {
      headerName: "Id",
      field: "Id",
      flex: 1,
    },
    {
      headerName: "Name",
      field: "FullName",
      flex: 1,
    },
    {
      headerName: "Report Template",
      field: "Report Template",
      flex: 1,
    },
    {
      headerName: "Extension",
      field: "Extension",
      flex: 1,
    },
    {
      headerName: "Mime Type",
      field: "Mime Type",
      flex: 1,
    },
    {
      headerName: "Created",
      field: "Created",
      flex: 1,
    },
    {
      headerName: "Uploaded",
      field: "Uploaded",
      flex: 1,
    },
    {
      headerName: "Uploaded By",
      field: "Uploaded By",
      flex: 1,
    },
    {
      headerName: "Modified",
      field: "Modified",
      flex: 1,
    },
    {
      headerName: "Modified By",
      field: "Modified By",
      flex: 1,
    },
    {
      headerName: "Report Type",
      field: "Report Type",
      flex: 1,
    },
  ];
}
function GetFormSections(
  usersDropdown,
  view,
  exportToExcelClick,
  auditsData,
  infoMessage,
  CanAdministrate
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "Name",
          label: "Name",
          xsCol: 3,
        },
        {
          name: "Type",
          label: "Type",
          type: "dropdown",
          xsCol: 3,
          required: true,
          selectOptions: (usersDropdown || []).map((x) => ({
            value: x.Username,
            text: x.FullName,
          })),
        },

        CanAdministrate && {
          xsCol: 4.5,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={view}
                sx={{ mt: "12px" }}
                startIcon={<FileUpload />}
              >
                Upload
              </Button>
            );
          },
        },
        CanAdministrate && {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={exportToExcelClick}
                sx={{ mt: "12px" }}
                startIcon={<SaveIcon />}
              >
                Save (F12)
              </Button>
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography
                sx={{
                  mt: 1,

                  fontSize: 11,
                  color: auditsData?.length > 0 ? "green" : "red",
                }}
              >
                {infoMessage}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const SearchFilterDefaults = {};
