import { useTheme } from "@emotion/react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import useFetch from "../../hooks/useFetch";
import { MASTER_DATA_APIS } from "../../common/apis";
import TextBox from "../../components/TextBox";
import { SaveIcon, UpdateIcon } from "../../icons/ButtonIcons";
import { MANAGEMENT_APIS } from "./../../common/apis";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function GroupEmailDomains({}) {
  const curTheme = useTheme();
  const { get, post } = useFetch();

  const [emailsData, setEmailsData] = useState([]);
  const [domainValue, setDomainValue] = useState();

  //get api for loading data
  useEffect(() => {
    document.title = "Group Email Domains";
    getDomainData();
  }, []);

  async function getDomainData() {
    let responseData = await get(MANAGEMENT_APIS.KOMATSU_DOMAINS);
    if (responseData) {
      setEmailsData(responseData);
    } else {
      setEmailsData();
    }
  }

  const tblOptions = {
    columns: GetColumns(deleteClick),
    dataSource: emailsData || [],
    rowIdProperty: "KomatsuDomainId",
    isDisableColumnFilter: true,
    sx: { height: 640 },
  };

  //Save data
  async function saveClicked() {
    await post(MANAGEMENT_APIS.ADD_KOMATSU_DOMAINS, {
      Domain: domainValue,
    });
    getDomainData();
  }
  async function deleteClick(row) {
    await get(
      `${MANAGEMENT_APIS.DELETE_KOMATSU_DOMAINS}?DomainId=${row.KomatsuDomainId}`
    );
    getDomainData();
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mt: curTheme.spacing(1) }}>
        <Grid item xs={8}>
          <Card sx={{ p: 1 }}>
            <TextBox
              name="site"
              label="Add Email Domain"
              placeholder="Do not include @"
              value={domainValue}
              onChange={(e) => setDomainValue(e.target.value)}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Button
            sx={{ marginTop: "25px" }}
            startIcon={<SaveIcon />}
            onClick={saveClicked}
          >
            Save
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(1) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {emailsData && emailsData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "Email Domain",
      field: "KomatsuDomain",
      minWidth: 300,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}
