import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { useEffect, useMemo, useState } from "react";
import SwitchInput from "../../../components/SwitchInput";
import { TextRotationAngleup } from "@mui/icons-material";
import TextBox from "../../../components/TextBox";
import { useId } from "react";
import TestItemsTable from "./TestItemsTable";
import { TEST_SETS_APIS } from "../../../common/apis";

const ScreenName = "Test Item Management";
const steps = [
  "Select Action",
  "Select Replacement Test Item",
  "Action Summary",
];

export default function ManageTestItem({ source, close, refreshTestItems }) {
  const [activeStep, setActiveStep] = useState(0);
  const [replacement, setReplacement] = useState({});

  //const [skipped, setSkipped] = useState(new Set());
  const [processResponse, setProcessResponse] = useState("");

  const isStepOptional = (step) => {};

  const [action, setAction] = useState("deleteOriginal");
  const { get } = useFetch();
  const handleNext = async () => {
    //let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    if (activeStep === 2) {
      await PerformOperation();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setSkipped(newSkipped);
  };

  async function PerformOperation() {
    const isDel = action === "deleteOriginal";
    const resp = await get(
      `${TEST_SETS_APIS.REPLACE_TEST_ITEMS}?src=${source.TestItemId}&dest=${replacement.TestItemId}&delSrc=${isDel}&screen=${ScreenName}`
    );
    if (resp?.Success) {
      const msg = isDel
        ? "The test item  " +
          source.TestItem +
          "  has been REPLACED by  " +
          replacement.TestItem +
          "  and then DELETED"
        : "The test item  " +
          source.TestItem +
          "  has been REPLACED by  " +
          replacement.TestItem;
      setProcessResponse(msg);
      refreshTestItems();
      return;
    }
    const msg = isDel
      ? "Unfortunately, the test item  " +
        source.TestItem +
        "  could NOT be replaced by  " +
        replacement.TestItem
      : "Unfortunately, the test item  " +
        source.TestItem +
        "  could NOT be replaced by  " +
        replacement.TestItem +
        "  and deleted";

    setProcessResponse("test");
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setReplacement({});
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      {activeStep === steps.length ? (
        <>
          <Grid item>
            <Typography variant="body2">{processResponse}</Typography>
          </Grid>
          <Grid item alignSelf="flex-end">
            <Button onClick={close}>Close</Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item container direction="column">
            <Grid item>
              {activeStep === 0 ? (
                <ActionSelectActionType setAction={setAction} />
              ) : activeStep === 1 ? (
                <ActionSelectReplacement
                  setReplacement={setReplacement}
                  source={source}
                />
              ) : (
                <Summary source={source} replacement={replacement} />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleNext}
                disabled={activeStep === 1 && !replacement.TestItemId}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
function Summary({ source = {}, replacement = {} }) {
  return (
    <Typography variant="subtitle2">
      <p>Source Test Item: {source.TestItem}</p>
      <p>Source Item Code: {source.TestItemCode}</p>
      <p>Source TestItemId: {source.TestItemId}</p>
      <p>------------------------------</p>
      <p>Replacement Test Item: {replacement.TestItem}</p>
      <p>Replacement Item Code: {replacement.TestItemCode}</p>
      <p>Replacement TestItemId: {replacement.TestItemId}</p>
    </Typography>
  );
}
function ActionSelectReplacement({ setReplacement, source }) {
  const { get } = useFetch();
  const id = useId();
  const [testItems, setTestItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [showArchived, setShowArchived] = useState(true);
  useEffect(function () {
    get(TEST_SETS_APIS.TEST_ITEMS).then(function (testItemsResp) {
      setTestItems(
        (testItemsResp || []).filter((x) => x.TestItemId !== source.TestItemId)
      );
    });
  }, []);
  const testItemsFiltered = useMemo(
    () =>
      testItems.filter(
        (x) =>
          (!x.IsArchived || x.IsArchived === showArchived) &&
          (!filter || x.TestItem?.toLowerCase().includes(filter.toLowerCase()))
      ),
    [showArchived, testItems, filter]
  );

  return (
    <Grid container spacing={2} sx={{ width: 1000 }}>
      <Grid item tem xs={4}>
        <TextBox
          id={id}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          label="Filter"
        />
      </Grid>
      <Grid item alignSelf="flex-end">
        <SwitchInput
          label="Show Archived"
          onChange={(e) => setShowArchived(e.target.checked)}
          defaultChecked
        />
      </Grid>
      <Grid item xs={12}>
        <TestItemsTable
          data={testItemsFiltered}
          rowSelected={(row) => setReplacement(row)}
          height={250}
        />
      </Grid>
    </Grid>
  );
}
function ActionSelectActionType({ setAction }) {
  return (
    <FormControl>
      <RadioGroup
        defaultValue="deleteOriginal"
        name="manageAction"
        onChange={(e) => setAction(e.target.value)}
      >
        <FormControlLabel
          value="deleteOriginal"
          control={<Radio />}
          label="Replace all instances of the test item with a different one, and DELETE the original test item."
        />
        <FormControlLabel
          value="keepOriginal"
          control={<Radio />}
          label="Replace all instances of the test item with a different one, but KEEP the original test item."
        />
      </RadioGroup>
    </FormControl>
  );
}
