import React from "react";
import DataTable2 from "./DataTable2";

export default function ApprovalToReleaseTable({
  data,
  rowSelected,
  sx,
  ...props
}) {

  const options = {
    rows: data,
  };
  return <DataTable2 {...options} sx={sx} {...props} />;
}
