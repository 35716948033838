import { alpha, styled } from "@mui/material/styles";
import TreeItemMui, { treeItemClasses } from "@mui/lab/TreeItem";
import { MinusSquare, PlusSquare } from "../../icons/ButtonIcons";
import productColors from "../../common/ProductColors";
import { useEffect, useState } from "react";

const StyledTreeItem = styled((props) => <TreeItemMui
  sx={{
    [`.MuiTreeItem-content.Mui-selected `]: { backgroundColor: "transparent" },
    // [`.MuiTreeItem-content:hover`]: { backgroundColor: props.productColor, }
  }}
  {...props} />)
  (
    ({ theme }) => ({
      [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
          opacity: 0.3,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 8,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      },
    })
  );

export default function TreeItem(props) {
  const { customProps, ...treeProps } = props;
  const options = {};
  if (customProps && customProps.hasChildren) {
    options.icon = customProps.expanded ? <MinusSquare /> : <PlusSquare />;
  }
  const [productColor, setProductColor] = useState({})

  useEffect(() => {
    let prod = props?.nodeId?.split("/")[0];
    let color1 = Number(prod?.split("_")[1]);
    let color2 = productColors(color1);
    setProductColor(color2);
  }, []);

  return <>
    <StyledTreeItem id={props?.selectedNode || ""}
      //  productColor={productColor}
      {...treeProps} {...options}></StyledTreeItem>
  </>
}
